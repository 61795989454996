/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadedPhotoInfoItem
 */
export interface UploadedPhotoInfoItem {
    /**
     * 
     * @type {number}
     * @memberof UploadedPhotoInfoItem
     */
    fileId: number;
    /**
     * 
     * @type {string}
     * @memberof UploadedPhotoInfoItem
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadedPhotoInfoItem
     */
    isFolder: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadedPhotoInfoItem
     */
    hasPreview: boolean;
    /**
     * 
     * @type {number}
     * @memberof UploadedPhotoInfoItem
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof UploadedPhotoInfoItem
     */
    exifCreatedAt?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadedPhotoInfoItem
     */
    ordering?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadedPhotoInfoItem
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof UploadedPhotoInfoItem
     */
    height: number;
}

export function UploadedPhotoInfoItemFromJSON(json: any): UploadedPhotoInfoItem {
    return UploadedPhotoInfoItemFromJSONTyped(json, false);
}

export function UploadedPhotoInfoItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadedPhotoInfoItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileId': json['fileId'],
        'name': json['name'],
        'isFolder': json['isFolder'],
        'hasPreview': json['hasPreview'],
        'size': json['size'],
        'exifCreatedAt': !exists(json, 'exifCreatedAt') ? undefined : json['exifCreatedAt'],
        'ordering': !exists(json, 'ordering') ? undefined : json['ordering'],
        'width': json['width'],
        'height': json['height'],
    };
}

export function UploadedPhotoInfoItemToJSON(value?: UploadedPhotoInfoItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileId': value.fileId,
        'name': value.name,
        'isFolder': value.isFolder,
        'hasPreview': value.hasPreview,
        'size': value.size,
        'exifCreatedAt': value.exifCreatedAt,
        'ordering': value.ordering,
        'width': value.width,
        'height': value.height,
    };
}

