import { useLocalStorage } from '@vueuse/core';
import flagsmith from 'flagsmith';
import { computed, ComputedRef, reactive, readonly, toRefs } from 'vue';

import { authApi, EAccountRole, IAuthAccountDto } from '@/api';
import RolesRights from '@/features/Auth/RolesRights';
import { AppRoutes, baseRoute } from '@/router/data';
import { getMillisecondsByTime } from '@/utils/date';
import { fullName } from '@/utils/name';

const AUTH_KEY = 'auth';

const cachedAccount = useLocalStorage<IAuthAccountDto | null>(AUTH_KEY, null, {
  serializer: {
    read: JSON.parse,
    write: JSON.stringify,
  },
});

const MAX_REFRESH_TOKEN_COUNT = 2;
let refreshCounter = 0;
let delayedResetRefreshCounter: ReturnType<typeof setTimeout> | null = null;

const state = reactive({
  account: cachedAccount,
  error: '',
});

const cacheAccount = (account: IAuthAccountDto) => {
  cachedAccount.value = account;
};

const getCachedAccount = (): IAuthAccountDto | null => {
  const account = localStorage.getItem(AUTH_KEY);

  if (!account) return null;

  try {
    return JSON.parse(account);
  } catch {
    return null;
  }
};

const removeCachedAccount = () => {
  cachedAccount.value = null;
};

export const isAuthorized = (role: string | undefined) =>
  Object.values(EAccountRole).includes(role as EAccountRole);

export const useAuth = () => {
  const isAuthenticated = computed(() => state.account !== null);
  const accountId = computed(() => state.account?.userInfo.id);
  const token = computed(() => state.account?.accessToken);
  const rtoken = computed(() => state.account?.refreshToken);
  const rolesRights: Record<string, EAccountRole[]> = RolesRights;

  const loginUrl = new URL(
    `${baseRoute}${AppRoutes.login.path}`,
    window.location.origin,
  );

  const role = computed(() => state.account?.userInfo.role);

  const getRoleRights = (scope: keyof typeof rolesRights) =>
    computed(() => {
      if (!role.value) return false;

      const isPhotostudioAdminRights = isCurrentRole(
        EAccountRole.PHOTOSTUDIO_ADMIN,
        EAccountRole.ADMIN,
        EAccountRole.DEVELOPER, // All rights admin for developers on client side, server side has limits
      );

      return (
        isPhotostudioAdminRights.value ||
        rolesRights[scope].includes(role.value)
      );
    });

  const isCurrentRole = (...args: EAccountRole[]): ComputedRef<boolean> =>
    computed((): boolean => {
      if (!role.value) return false;

      return [...args].includes(role.value);
    });

  return {
    isAuthenticated,
    token: token.value,
    rtoken: rtoken.value,
    isAuthorized: computed(() => isAuthorized(role.value)),
    loginUrl,
    role,
    isPhotostudioAdmin: isCurrentRole(
      EAccountRole.PHOTOSTUDIO_ADMIN,
      EAccountRole.ADMIN,
    ),
    isRetoucher: isCurrentRole(EAccountRole.PHOTOSTUDIO_RETOUCHER),
    isSupport: isCurrentRole(EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST),
    isProducer: isCurrentRole(EAccountRole.PHOTOSTUDIO_PRODUCER),
    isPhotographer: isCurrentRole(EAccountRole.PHOTOSTUDIO_PHOTOGRAPHER),
    canReviewPhoto: getRoleRights('canReviewPhoto'),
    canMarkDefects: getRoleRights('canMarkDefects'),
    canRemoveItem: getRoleRights('canRemoveItem'),
    canEditBoxes: getRoleRights('canEditBoxes'),
    canEditRequests: getRoleRights('canEditRequests'),
    canEditInvoices: getRoleRights('canEditInvoices'),
    canChangeRetoucherRequest: getRoleRights('canChangeRetoucherRequest'),
    canChangeIssueEditorRequest: getRoleRights('canChangeIssueEditorRequest'),
    canPrintSkuBarcode: getRoleRights('canPrintSkuBarcode'),
    canViewReservations: getRoleRights('canViewReservations'),
    canAddReservation: getRoleRights('canAddReservation'),
    canAddRequestComment: getRoleRights('canAddRequestComment'),
    canShowPhotographerProgressInRequest: getRoleRights(
      'canShowPhotographerProgressInRequest',
    ),
    canShowRetoucherProgressInRequest: getRoleRights(
      'canShowRetoucherProgressInRequest',
    ),
    accountId,
    ...toRefs(readonly(state)),
  };
};

const identifyFlagsmith = async (account: IAuthAccountDto) => {
  if (!account || !account.userInfo) return;

  const id = `[${account.userInfo.id}] ${fullName(account.userInfo)}`;

  await flagsmith.identify(id, { role: account.userInfo.role });
};

export const useAuthMethods = () => {
  const rehydrateAccount = async () => {
    state.account = getCachedAccount();

    if (state.account) {
      await identifyFlagsmith(state.account);
    }

    return state.account;
  };

  const login = async (
    username: string,
    password: string,
  ): Promise<IAuthAccountDto> => {
    state.account = await authApi.login({
      username,
      password,
      grant_type: 'password',
    });

    if (state.account) {
      cacheAccount(state.account);
    }

    return state.account;
  };

  const refreshToken = async () => {
    refreshCounter++;

    if (refreshCounter > MAX_REFRESH_TOKEN_COUNT) {
      logOut();
    }

    if (state.account) {
      state.account = await authApi.refreshToken(state.account.refreshToken);

      cacheAccount(state.account);

      await identifyFlagsmith(state.account);

      if (delayedResetRefreshCounter) {
        clearTimeout(delayedResetRefreshCounter);
        delayedResetRefreshCounter = null;
      }

      delayedResetRefreshCounter = setTimeout(() => {
        refreshCounter = 0;
      }, getMillisecondsByTime({ seconds: 30 }));

      return state.account;
    }
  };

  const logOut = () => {
    removeCachedAccount();
    state.account = null;
  };

  return { login, refreshToken, rehydrateAccount, logOut };
};
